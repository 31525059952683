<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.edit_bulletin") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updateBulletin"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";

export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const bulletin = computed(() => store.getters["bulletin/getBulletin"]);
    const company = computed(() => store.state.company.company);
    const estates = computed(() => store.state.estate.estates);
    const roles = computed(() => store.state.role.roles);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.bulletin.waiting) return true;
      if (store.state.estate.waiting) return true;
      if (store.state.role.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const formElements = ref(null);

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "topic",
          label: "topic",
          rules: ["isRequired"],
          type: "text",
          value: bulletin.value.topic,
          variable: "topic",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "textarea",
          id: "content",
          label: "content",
          rows: 5,
          rules: ["isRequired"],
          value: bulletin.value.content,
          variable: "content",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "filepicker",
          accept: "application/pdf",
          disabled: false,
          id: "file",
          label: "file",
          multiple: false,
          rules: [],
          showFilename: true,
          textVariant: company.value.primaryText,
          value: bulletin.value.file,
          variable: "file",
          variant: company.value.primary,
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "multiselect",
          id: "estates",
          label: "estates",
          optionLabel: ["name"],
          options: estates,
          rules: ["isRequired"],
          value: bulletin.value.estateIds,
          variable: "estateIds",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "multiselect",
          id: "groups",
          label: "groups",
          optionLabel: ["label"],
          options: roles.value.filter(
            (role) => !role.label.includes("partner") && role.label != "admin"
          ),
          rules: ["isRequired"],
          translatableLabel: "roles",
          value: bulletin.value.roleIds,
          variable: "roleIds",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "checkbox",
          id: "publish",
          label: "publish",
          invalid: false,
          rules: [],
          value: bulletin.value.publish,
          variable: "publish",
          variant: company.value.primary,
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: company.value.primaryText,
          type: "submit",
          variant: company.value.primary,
        },
      ];
    };

    const updateBulletin = (formData) => {
      const data = {
        bulletinId: bulletin.value.id,
        companyId: company.value.id,
        content: formData.content,
        estateIds: formData.estateIds,
        file: formData.file.file ? formData.file : null,
        publish: formData.publish,
        roleIds: formData.roleIds,
        topic: formData.topic,
      };
      store.dispatch("bulletin/updateBulletin", data);
    };

    onMounted(() => {
      store.dispatch("bulletin/getBulletin", route.params.bulletinId);
      if (!estates.value.length) {
        store.dispatch("estate/getEstates", user.value.companyId);
      }
      if (!roles.value.length) {
        store.dispatch("role/getRoles");
      }
    });

    watch(
      () => waiting.value,
      (waiting) => {
        if (!waiting) {
          setFormElements();
        }
      }
    );

    return {
      formElements,
      t,
      updateBulletin,
      waiting,
    };
  },
};
</script>
